
























import Vue from "vue";
import store from "@/store";
import { CallType } from "@/models";
import moment from "moment";
import { Component, Prop } from "vue-property-decorator";
import DialPad from "@/components/DialPad.vue";

@Component({
  components: { DialPad },
})
export default class CallInProgressView extends Vue {
  @Prop() public muted!: boolean;
  @Prop() public onDialpadClick!: (number: string) => void;
  @Prop() public onMute!: (mute: boolean) => void;

  private dialMode = false;
  private input = "";

  get caller(): string {
    return store.state.currentCall
      ? store.state.currentCall.info.type === CallType.inbound
        ? store.state.currentCall.info.from
        : store.state.currentCall.info.to
      : "unknown";
  }
  get duration(): string {
    return store.state.currentCall
      ? moment.utc(store.state.currentCall?.info.duration).format("mm:ss")
      : "00:00";
  }

  get videoEnabled(): boolean {
    return store.state.settings.videoEnabled;
  }

  toggleDialMode(): void {
    this.dialMode = !this.dialMode;
  }

  onDialPadClick(number: string): void {
    this.input = this.input + `${number}`;
    this.onDialpadClick(number);
  }

  handleMute(): void {
    this.onMute(!this.muted);
  }

  onHandleVideo(): void {
    store.dispatch("toggleVideoEnabled");
  }
}
