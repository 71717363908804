export enum CallType {
    inbound,
    outbound
}

export enum CallStatus {
    ringing,
    inProgress,
    ended,
    rejected,
    declined
}

export interface CallInfo {
    id: string
    from: string
    to: string
    type: CallType
    status: CallStatus
    duration: number
    date: string
}

export class Call {
   public info: CallInfo
   private timer?: number

    constructor(info: CallInfo) {
        this.info = info
    }

    public start(): void {
        this.info.status = CallStatus.inProgress
        this.timer = setInterval(() => {
            this.info.duration += 1000
        }, 1000)
    }

    public stop(status: CallStatus): void {
        this.info.status = status
        if (this.timer) clearInterval(this.timer)
    }
}