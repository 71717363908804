


































































import Vue from "vue";
import store from "@/store";
import Component from "vue-class-component";
import { UserModel } from "@/models";
import { Watch } from "vue-property-decorator";
import { Flow } from "@/api/flows";

enum ContactListMode {
  contact,
  flow,
}
@Component
export class ContactListModal extends Vue {
  private mode: ContactListMode = ContactListMode.contact;

  get isContactMode(): boolean {
    return this.mode === ContactListMode.contact;
  }

  get isFlowMode(): boolean {
    return this.mode === ContactListMode.flow;
  }

  get contacts(): UserModel[] {
    return store.state.contacts.data;
  }

  get flows(): Flow[] {
    return store.state.flows.data;
  }

  get isLoading(): boolean {
    return store.state.contacts.loading || store.state.flows.loading;
  }

  public open(): void {
    (this.$refs.contactListModal as ContactListModal).open();
  }

  public close(): void {
    (this.$refs.contactListModal as ContactListModal).close();
  }

  setContactMode(): void {
    this.mode = ContactListMode.contact;
  }

  setFlowMode(): void {
    this.mode = ContactListMode.flow;
  }

  private fetchData() {
    switch (this.mode) {
      case ContactListMode.contact:
        store.dispatch("fetchContacts");
        break;
      case ContactListMode.flow:
        store.dispatch("fetchFlows");
        break;
    }
  }

 onSelectContact(contact: UserModel): void {
    this.$emit("contact-select", contact);
    this.close();
  }

  onSelectFlow(flow: Flow): void {
    console.log('FLOW:', flow)
    this.$emit("flow-select", flow);
    this.close();
  }

  @Watch("mode")
  modeChanged(): void {
    this.fetchData();
  }
}

export default ContactListModal
