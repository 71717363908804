











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DialPad extends Vue {
  @Prop() private onDialpadClick!: (number: string) => void;

  private keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  private value = "";

  created(): void {
    window.addEventListener("keyup", (ev: KeyboardEvent) => {
      if (this.keys.includes(ev.key) || ["#", "*", "0"].includes(ev.key)) {
        this.press(ev.key);
      }
    });
  }

  press(key: string): void {
    this.value = `${this.value}${key}`;
    this.onDialpadClick(key);
  }
}
