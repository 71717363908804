


































import { Component, Vue } from "vue-property-decorator";
import SoftPhone from "@/components/SoftPhone.vue"; // @ is an alias to /src
import HorizontalCollapse from "@/components/HorizontalCollapse.vue";
import CallsLogsView from "@/components/CallsLogsView.vue"
import store from "@/store";
import config from "@/config";

@Component({
  components: {
    SoftPhone,
    HorizontalCollapse,
    CallsLogsView
  },
})
export default class Dialer extends Vue {

  readonly environmentUrl: string = (config.CLIENT_DOMAIN as string).substring(1)

  get userName(): string {
    return store.state.user.username;
  }
  get userToken(): string {
    return store.state.user.token;
  }
  get userId(): string {
    return store.state.user.userId;
  }
  get notificationsMuted(): boolean {
    return store.state.settings.notificationsMuted;
  }

  toggleMuteIncomingCall(): void {
    store.dispatch("toggleNotificationsMuted");
  }
}
