  <template>
  <section class="js-horizontal-collapse horizontal-collapse">
    <ul class="horizontal-collapse__inner">
      <HorizontalCollapseItem className="horizontal-collapse__item--1">
        <div class="horizontal-collapse__inactive-content">
          <img :src="require('../assets/arrow_back_ios-white.svg')" />
        </div>
        <div class="horizontal-collapse__active-content">
          <h2 class="horizontal-collapse__heading">Calls</h2>
          <slot></slot>
          <img
            class="horizontal-collapse__heading-icon"
            :src="require('../assets/arrow_back_ios-white.svg')"
          />
        </div>
      </HorizontalCollapseItem>
    </ul>
  </section>
</template>

<script>
import HorizontalCollapseItem from "./HorizontalCollapseItem";
export default {
  name: "HorizontalCollapse",
  components: {
    HorizontalCollapseItem,
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

$transitionDurationMs: 0.5s;
.horizontal-collapse {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
  color: #fff;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  &__inner {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
  }
  &__item {
    height: 100%;
    list-style: none;
    padding: 0;
    overflow: hidden;
    min-width: 10rem;
    max-width: 40rem;
    position: relative;
    transition: width $transitionDurationMs;
    &.is-active {
      .horizontal-collapse__inactive-content {
        opacity: 0;
      }
      .horizontal-collapse__active-content {
        opacity: 1;
      }
    }
  }
  &__item-inner {
    padding: 2rem;
  }
  &__inactive-content {
    opacity: 1;
    transition: opacity $transitionDurationMs;
    position: absolute;
    top: calc(50% - 24px);
    left: 4px;
    .horizontal-collapse__heading {
      user-select: none;
      cursor: default;
      font-size: 3rem;
      line-height: 0;
      margin-bottom: 0;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
    }
  }
  &__active-content {
    opacity: 0;
    height: 100%;
    transition: opacity $transitionDurationMs;
    cursor: default;
    .horizontal-collapse__heading-icon {
      position: absolute;
      transform: rotate(180deg);
      left: -8px;
      top: calc(50% - 24px);
    }
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  &__body {
    margin-bottom: 2rem;
  }
}
</style>