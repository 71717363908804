





















export interface HorizontalCollapseItemState {
  isActive: boolean,
  itemMinWidth: number,
  itemMaxWidth: number,
}

export default {
  name: "HorizontalCollapseItem",
  data(): HorizontalCollapseItemState {
    return {
      isActive: false,
      itemMinWidth: 40,
      itemMaxWidth: 280,
    };
  },
  props: ["className"],
};
