















import store from "@/store";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class IncomingCallView extends Vue {
  private ringback = new Audio(require(`../assets/ringback.wav`));

  get to(): string {
    return store.state.currentCall?.info.to ?? "unknown";
  }

  mounted(): void {
    this.ringback.loop = true;
    this.ringback.play();
  }

  beforeDestroy(): void {
    this.ringback.pause();
  }
}
