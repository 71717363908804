import Vue from "vue";
import Vuex from "vuex";
import config from "@/config";
import { UserModel, CallInfo, Call, CallStatus } from "@/models";
import http, { AxiosResponse } from "axios";
import getFlowsApisApi, { Flow } from "@/api/flows";
import _ from "lodash";
import moment from "moment";
import * as Sentry from "@sentry/vue";
import { ActionContext } from 'vuex'

Vue.use(Vuex);

const PROVIDERS_URL = `${config.SDK_API_URL}/http/${config.PROVIDERS_ACCOUNT_ID}`;

export interface Identifier {
  keywords: string[]
  type: string
  used: {
    botId: string
    flowId: string
    flowLabel: string
  },
  value: string
}

export interface AppState {
    settings: {
      notificationsMuted: boolean
      videoEnabled: boolean
    }
    user: UserModel
    currentCall: Call | null
    contacts: {
      loading: boolean
      data: UserModel[]
    }
    flows: {
      loading: boolean
      data: Flow[]
    }
    calls: {
      loading: boolean
      data: Call[]
    }
}

export const initialState: AppState = {
    settings: {
      notificationsMuted: true,
      videoEnabled: false
    },
    user: {
      id: "",
      accountId: "",
      allow: false,
      role: "",
      token: "",
      tokenType: "",
      twoFactorEnabled: false,
      userId: "",
      username: ""
    },

    currentCall: null,

    contacts: {
      loading: false,
      data: []
    },

    flows: {
      loading: false,
      data: []
    },

    calls: {
      loading: false,
      data: []
    }
}

export type SPStoreContext = ActionContext<AppState, unknown>


export default new Vuex.Store<AppState>({
  state: initialState,

  getters: {
    calls: (state: AppState) => {
      return state.calls.data.sort(
        (a, b) => moment(b.info.date).unix() - moment(a.info.date).unix()
      );
    }
  },

  mutations: {
    setCurrentUser(state: AppState, user: UserModel) {
      state.user = user;
    },
    setContacts(state: AppState, { loading = false, data = state.contacts.data }) {
      state.contacts = { loading, data };
    },
    setFlows(state: AppState, { loading = false, data = state.flows.data }) {
      console.log(state.flows)
      state.flows = { loading, data };
    },
    setSettings(state: AppState,
      {
        notificationsMuted = state.settings.notificationsMuted,
        videoEnabled = state.settings.videoEnabled
      }
    ) {
      state.settings = { notificationsMuted, videoEnabled };
    },
    setCurrentCall(state: AppState, call: Call | null) {
      state.currentCall = call;
    },
    updateCurrentCall(state: AppState, callInfo: CallInfo) {
      if (state.currentCall) state.currentCall.info = callInfo;
    },
    setCallsLogs(state: AppState, logs: Array<Call>) {
      state.calls.data = logs;
    },
    addCallToLog(state: AppState, call: Call) {
      state.calls.data.push(call);
      localStorage.setItem("callsLogs", JSON.stringify(state.calls.data));
    },
    updateCallInLog(state: AppState, call: Call) {
      const callLog = state.calls.data.find(c => c.info.id === call.info.id);
      if (callLog) {
        callLog.info = call.info;
      }
      localStorage.setItem("callsLogs", JSON.stringify(state.calls.data));
    }
  },

  actions: {
    toggleNotificationsMuted(context: SPStoreContext) {
      const { state, commit } = context
      commit("setSettings", {
        notificationsMuted: !state.settings.notificationsMuted
      });
    },

    toggleVideoEnabled(context: SPStoreContext) {
      const { state, commit } = context
      commit("setSettings", {
        videoEnabled: !state.settings.videoEnabled
      });
    },

    setCurrentUser(context: SPStoreContext, user: UserModel) {
      context.commit("setCurrentUser", user);
    },

    fetchLocalStorageData(context: SPStoreContext) {
      const { commit } = context
      const logsString = localStorage.getItem("callsLogs");
      if (logsString) {
        commit("setCallsLogs", JSON.parse(logsString));
      }
    },

    setCurrentCall(context: SPStoreContext, call: Call | null = null) {
      context.commit("setCurrentCall", call);
      context.commit("addCallToLog", call);
    },

    startCurrentCall(context: SPStoreContext) {
      context.state.currentCall?.start();
      context.commit("updateCallInLog", context.state.currentCall);
    },

    stopCurrentCall(context: SPStoreContext, status: CallStatus) {
      context.state.currentCall?.stop(status);
      context.commit("updateCallInLog", context.state.currentCall);
    },

    async fetchContacts(context: SPStoreContext) {
      const { state, commit } = context
      commit("setContacts", { loading: true });

      try {
        const { data } = await http.get(`${config.SDK_API_URL}/users`, {
          headers: { Authorization: `USER ${state.user.token}` }
        });
        commit("setContacts", { data });
      } catch (error) {
        console.error(error);
        commit("setContacts", { data: [] });
        Sentry.captureException(error);
      }
    },

    async fetchFlows(context: SPStoreContext) {
      const { state, commit } = context
      commit("setFlows", { loading: true });

      try {
        const identifiersData = await http.get(`${PROVIDERS_URL}/identifiers`, {
          params: {
            fullInfo: true,
            groupDetails: true
          },
          headers: { Authorization: `USER ${state.user.token}` }
        });

        const { data } = await http.post<unknown, AxiosResponse<Identifier[]>>(
          `${PROVIDERS_URL}/get-identifiers-triggers`,
          {
            identifiers: identifiersData.data.map(
              (identifier: {
                id: string
              }) => identifier.id
            ),
            accountId: state.user.accountId
          },
          {
            headers: {
              Authorization: `${state.user.token}`
            }
          }
        );

        const usedFlowsIds = _.chain(data)
          .map("used.flowId")
          .uniq()
          .value();
        const flowsApi = getFlowsApisApi();
        const flowsMeta = await flowsApi.getFlows({
          query: { id: usedFlowsIds }
        });

        _.forEach(data, (el) => {
          const flow = flowsMeta.find((fl) => fl.id === el.used.flowId) //_.find(flowsMeta, ["id", el.used.flowId]);
          if (flow) {
            el.used.flowLabel = _.get(flow, "data.label") || "No name";
            el.used.botId = _.get(flow, "botId");
          }
        });

        commit("setFlows", { data });
      } catch (error) {
        console.error(error);
        commit("setFlows", { data: [] });
        Sentry.captureException(error);
      }
    }
  }
});
