






























import Vue from "vue";
import moment from "moment";
import store from "@/store";
import { Component } from "vue-property-decorator";
import { Call, CallStatus, CallType } from "@/models";
import EventBus from "@/helpers/eventBus";

@Component({})
export default class CallsLogsView extends Vue {
  get calls(): Array<Call> {
    return store.getters.calls;
  }
  callLabel(call: Call): string {
    return call.info.type === CallType.inbound ? call.info.from : call.info.to;
  }
  callDate(call: Call): string {
    return moment(call.info.date).format("ddd h:mm A");
  }
  callTypeIcon(call: Call): string {
    switch (call.info.status) {
      case CallStatus.rejected:
        return "call_missed_outgoing";
      case CallStatus.declined:
        return "call_missed";
      default:
        return call.info.type === CallType.inbound
          ? "call_received"
          : "call_made";
    }
  }
  onCall(call: Call): void {
    EventBus.$emit("make-call", call);
  }
}
