import { Auth } from '@onereach/sdk';
import config from '@/config'
import { IUser } from '@/main';

const authParams = {
    cookieDomain         : config.COOKIE_DOMAIN,
    authUrl              : `${config.SDK_API_URL}/auth/token`,
    allowGuestLogin      : Boolean(config.GUEST_LOGIN),
    userCookieName       : `${config.COOKIE_NAME_PREFIX}_name`,
    userExpireCookieName : `${config.COOKIE_NAME_PREFIX}_user_expire`,
    expireInLong         : `${config.COOKIE_EXPIRATION_LONG}`,
    sdkApiUrl            : `${config.SDK_API_URL}` 
}
const auth = new Auth(authParams);

const verifyCookie = async (): Promise<IUser | undefined> => {
    try {
        const user = await auth.validateUser() as IUser;
        return user;
    } catch (e) {
        window.location.href = `${config.AUTH_UI_URL}/?redirectPath=${encodeURI(
            window.location.href
        )}`;
    }
};

export { verifyCookie, auth as default };
