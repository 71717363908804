















import store from "@/store";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class IncomingCallView extends Vue {
  private incomingCallRingtone = new Audio(require("../assets/cellular.wav"));

  get muted(): boolean {
    return store.state.settings.notificationsMuted;
  }

  get caller(): string {
    return store.state.currentCall?.info.from ?? "unknown";
  }

  mounted(): void {
    this.incomingCallRingtone.loop = true;
    this.incomingCallRingtone.defaultMuted = this.muted;
    this.incomingCallRingtone.play();
  }

  beforeDestroy(): void {
    this.incomingCallRingtone.pause();
  }

  @Watch("muted")
  handleMuted(newValue: boolean): void {
    this.incomingCallRingtone.muted = newValue;
    newValue
      ? this.incomingCallRingtone.pause()
      : this.incomingCallRingtone.play();
  }
}
