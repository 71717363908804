









































































































import config from "@/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SimpleUser, SimpleUserDelegate } from "@/services/sip/index";
import { SimpleUserOptions } from "sip.js/lib/platform/web";
import {
  Bye,
  Info,
  InviterInviteOptions,
  InviterOptions,
  Message,
  Referral,
  SIPExtension,
  Notification,
  SessionDelegate,
  Invitation,
  Inviter,
} from "sip.js/lib/api";
// import { Session } from 'sip.js'
import DialPad from "@/components/DialPad.vue";
import {ContactListModal} from "./ContactListModal.vue";
import IncomingCallView from "./IncomingCallView.vue";
import OutboundCallView from "./OutboundCallView.vue";
import CallInProgressView from "./CallInProgressView.vue";
import { Interpreter, State } from "xstate";
import { interpret } from "xstate";
import {
  IncomingRequestMessage,
  IncomingResponse,
  OutgoingRequestDelegate,
  OutgoingRequestMessage,
} from "sip.js/lib/core";
import { UserModel, CallType, CallStatus, Call } from "@/models";
import store, { Identifier } from "@/store";
import moment from "moment";
import {
  PhoneStateContext,
  PhoneStateSchema,
  PhoneEvent,
  stateMachine,
} from "@/models";
import EventBus from "@/helpers/eventBus";
import * as Sentry from "@sentry/vue";
import packageJson from '../../package.json'

@Component({
  components: {
    DialPad,
    ContactListModal,
    IncomingCallView,
    OutboundCallView,
    CallInProgressView,
  },
})
export default class SoftPhone
  extends Vue
  implements SimpleUserDelegate, OutgoingRequestDelegate, SessionDelegate {
  @Prop() readonly msg!: string;
  @Prop() readonly wssAddress!: string;
  @Prop() readonly myUsername!: string;
  @Prop() readonly userId!: string;
  @Prop() readonly password!: string;
  @Prop() readonly number?: string;
  private environmentUrl: string = (config.CLIENT_DOMAIN as string).substring(1)
  /**
   * State Machine
   */
  private sm: Interpreter<
    PhoneStateContext,
    PhoneStateSchema,
    PhoneEvent,
    {
      value: string;
      context: PhoneStateContext;
    }
  > = interpret(stateMachine);

  private smState: State<
    PhoneStateContext,
    PhoneEvent,
    PhoneStateSchema,
    {
      value: string;
      context: PhoneStateContext;
    }
  > = this.sm.initialState;

  // private smContext?: PhoneStateContext = stateMachine.context;
  private simpleUser?: SimpleUser;

  private destinationAddress = "";

  async initConnection(): Promise<void> {
    console.log("Initialize connection");

    const token = `USER ${this.password}`;
    const options: SimpleUserOptions = {
      aor: `sip:${this.userId}@${this.environmentUrl}`,
      userAgentOptions: {
        allowLegacyNotifications: true,
        authorizationPassword: token,
        authorizationUsername: token,
        logBuiltinEnabled: true,
        noAnswerTimeout: 120,
        userAgentString: `OR Softphone v${packageJson.version}`,
        // preloadedRouteSet: [{}]
        sipExtension100rel: SIPExtension.Supported,
        sipExtensionReplaces: SIPExtension.Supported,
        // sendInitialProvisionalResponse: true
        // forceRport: true,
      },
      media: {
        local: {
          video: this.$refs["localVideoComponent"] as HTMLVideoElement,
        },
        remote: {
          video: this.$refs["remoteVideoComponent"] as HTMLVideoElement,
        },
      },
      
    };
    try {
      this.simpleUser = new SimpleUser(this.wssAddress, options);
      this.simpleUser.delegate = this;
      this.sm?.send("CONNECT");
      await this.simpleUser.connect();
      await this.simpleUser.register({
        refreshFrequency: 50
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error connecting", { err });
    }
  }

  mounted(): void {
    this.sm
      .onTransition((state) => {
        // Update the current state component data property with the next state
        this.smState = state;
        // Update the context component data property with the updated context
        // this.smContext = state.context;
      })
      .start();
    this.initConnection();
    EventBus.$on("make-call", this.handleMakeCallRequest);
  }

  toggleContactListModal = (): void => {
    (this.$refs.contactListModal as ContactListModal).open();
  }

  onContactSelected(contact: UserModel): void {
    this.destinationAddress = `sip:${contact.id}@${this.environmentUrl}`;
    console.log("Contact selected:", contact);
  }

  onFlowSelected(flow: Identifier): void {
    this.destinationAddress = `sip:${flow.value}@${this.environmentUrl}`;
  }

  handleMakeCallRequest(call: Call): void {
    this.destinationAddress = `sip:${call.info.to}@${this.environmentUrl}`;
    this.doCall();
  }

  handleMute(mute: boolean): void {
    if (this.isCallInProgress) {
      if (mute) {
        this.simpleUser?.mute();
        this.sm.send("MUTE");
      } else {
        this.simpleUser?.unmute();
        this.sm.send("UNMUTE");
      }
    }
  }

  handleCallBtnPress(): void {
    if (this.smState.matches("wsConnected.resting")) {
      this.doCall();
    } else if (this.smState.matches("wsConnected.incomingCall")) {
      this.doAnswer();
    }
    return;
  }

  handleHangupBtnPress(): void {
    if (
      this.smState.matches("wsConnected.callInProgress") ||
      this.smState.matches("wsConnected.ringing")
    ) {
      this.doHangup();
    } else if (this.smState.matches("wsConnected.incomingCall")) {
      this.doReject();
    }
    return;
  }

  /**
   * UI Event Handlers
   */
  async doCall(): Promise<void> {
    console.log(`Do Call to ${this.destinationAddress}`);

    const inviterOptions: InviterOptions = {
      earlyMedia: false,
      inviteWithoutSdp: false,
      anonymous: false,
      delegate: this,
    };

    const inviterInviteOptions: InviterInviteOptions = {
      withoutSdp: false,
      requestDelegate: this,
    };

    try {
      await this.simpleUser?.call(
        this.destinationAddress,
        inviterOptions,
        inviterInviteOptions
      ); // ,
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error starting a call", { err });
      // this.sm.send('CALL') // TODO send Call Failed!
    }
  }

  async doAnswer(): Promise<void> {
    console.log("Do Answer");
    await this.simpleUser?.answer({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
    });
    this.sm.send("ANSWER");
  }

  doHangup(): void {
    console.log("Do Hangup");
    this.sm.send("HANGUP");
    this.simpleUser?.hangup();
  }

  doReject(): void {
    console.log("Do Reject");
    this.sm.send("REJECT");
    this.sm.send("PROCEED");
    this.simpleUser?.decline();
  }

  /**
   * Button States
   */
  isCallBtnDisabled(): boolean {
    let disabled = false;

    if (
      this.smState.matches("wsConnected.ringing") ||
      this.smState.matches("wsConnected.callInProgress") ||
      (!this.destinationAddress &&
        !this.smState.matches("wsConnected.incomingCall"))
    ) {
      disabled = true;
    }

    return disabled;
  }

  isHangupBtnDisabled(): boolean {
    let disabled = true;

    if (
      this.smState.matches("wsConnected.callInProgress") ||
      this.smState.matches("wsConnected.incomingCall") ||
      this.smState.matches("wsConnected.ringing")
    ) {
      disabled = false;
    }

    return disabled;
  }

  get isOutboundCall(): boolean {
    return this.smState.matches("wsConnected.ringing");
  }

  get isIncomingCall(): boolean {
    return this.smState.matches("wsConnected.incomingCall");
  }

  get isCallInProgress(): boolean {
    return this.smState.matches("wsConnected.callInProgress");
  }

  get isCallMuted(): boolean {
    return this.smState.matches("wsConnected.callInProgress.inProgress.muted");
  }

  get isVideoEnabled(): boolean {
    return store.state.settings.videoEnabled;
  }

  /**
   * SimpleUserDelegate Methods
   */
  onCallAnswered(): void {
    console.log("Call answered");
    store.dispatch("startCurrentCall");
    this.sm.send("ANSWERED");
  }

  onCallCreated(session: Inviter): void {
    console.log("onCallCreated", session);
    // const sess = session //as (Session & {request: IncomingRequestMessage | OutgoingRequestMessage})
    if (session.request instanceof IncomingRequestMessage) {
      if (session.request.from.uri.user && session.request.to.uri.user) {
        store.dispatch(
          "setCurrentCall",
          new Call({
            id: session.request.callId,
            from: session.request.from.uri.user,
            to: session.request.to.uri.user || '',
            type: CallType.inbound,
            status: CallStatus.ringing,
            duration: 0,
            date: moment().toString(),
          })
        );
        this.sm.send("INCOMING_CALL");
      } else {
        console.error('There is no FROM or TO user in the session', session)
      }
    } else if (session.request instanceof OutgoingRequestMessage) {
      if (session.request.from.uri.user && session.request.to.uri.user) {
        store.dispatch(
          "setCurrentCall",
          new Call({
            id: session.request.callId,
            from: session.request.from.uri.user,
            to: session.request.to.uri.user,
            type: CallType.outbound,
            status: CallStatus.ringing,
            duration: 0,
            date: moment().toString(),
          })
        );
        this.sm.send("CALL");
      } else {
        console.error('There is from or to user in the session', session)
      } 
    }
  }

  async onCallReceived(info: Invitation): Promise<void> {
    console.log("onCallReceived", info);
  }

  onCallHangup(): void {
    console.log("Call hangup");
    store.dispatch(
      "stopCurrentCall",
      store.state.currentCall?.info.duration !== 0
        ? CallStatus.ended
        : CallStatus.declined
    );
    this.sm.send("HANGUP");
    this.sm.send("PROCEED");
  }

  onCallHold(held: boolean): void {
    console.log("Call held", held);
    held ? this.sm.send("HOLD") : this.sm.send("UNHOLD");
  }

  onCallDTMFReceived(message: string): void {
    console.log("Call DTMF received", { message });
  }

  onMessageReceived(message: string): void {
    console.log("Call message received", { message });
  }

  onRegistered(): void {
    console.log("On Registered");
  }

  onUnregistered(): void {
    console.log("On Unregistered");
  }

  onServerConnect(): void {
    console.log("User is connected to server. ", this.smState.value);
    // this.sm?.send('CONNECT')
    if (this.smState.matches("wsNotConnected")) {
      this.sm?.send("CONNECT");
    }
    this.sm?.send("CONNECTED");
  }

  onServerDisconnect(error?: Error): void {
    console.log("On Server Disconnect", { error });
    this.sm?.send("DISCONNECTED");
  }

  /**
   * Dialpad Callback
   */
  onDialpadClick(number: string): void {
    console.log("Clicked ", number);
    const symb = number === "*" ? "_" : number;
    const dtmf = new Audio(require(`../assets/dtmf_${symb}.wav`));
    dtmf.play();

    if (!this.smState.matches("wsConnected.callInProgress")) {
      return;
    }

    this.simpleUser?.sendDTMF(number);
  }

  /**
   * OutgoingRequestDelegate
   */

  onAccept(resp: IncomingResponse): void {
    console.log("OnAccept: ", resp);
  }

  onProgress(resp: IncomingResponse): void {
    console.log("onProgress: ", resp);
  }

  onRedirect(resp: IncomingResponse): void {
    console.log("onRedirect: ", resp);
  }

  onReject(resp: IncomingResponse): void {
    console.log("onReject: ", resp);
    store.dispatch("stopCurrentCall", CallStatus.rejected);
    this.sm.send("REJECTED", resp);
    this.sm.send("PROCEED");
  }

  onTrying(resp: IncomingResponse): void {
    console.log("onTrying: ", resp);
  }

  /**
   * SessionDelegate
   */

  onBye(bye: Bye): void {
    console.log("onBye", { bye });
  }

  onInfo(info: Info): void {
    console.log("onInfo", { info });
  }

  onInvite?(
    request: IncomingRequestMessage,
    response: string,
    statusCode: number
  ): void {
    console.log("onInvite", { request, response, statusCode });
  }

  onMessage?(message: Message): void {
    console.log("onMessage", { message });
  }

  onNotify?(notification: Notification): void {
    console.log("onNotify", { notification });
  }

  onRefer?(referral: Referral): void {
    console.log("onRefer", { referral });
  }
}
