import Vue, { VueConstructor, VNode} from "vue";
import App from "./App.vue";
import orUi from "@onereach/ui";
import { verifyCookie } from "./api/auth";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import store from "@/store";
import version from "./version.json";

import "./registerServiceWorker";
import "normalize.css/normalize.css";
import "@onereach/ui/dist/or-ui.css";
import "keen-ui/dist/keen-ui.css";
import "material-design-icons/iconfont/material-icons.css";
import { DefaultProps } from "vue/types/options";


Sentry.init({
  Vue: Vue,
  dsn: "https://cd295f6119d64e6984c92180134d58b5@sentry.monit.onereach.ai/11",
  integrations: [new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0,
  environment: version.environment,
  release: version.version,
});


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.performance = true
Vue.use(orUi);

export interface IUser {
  accountId: string
  allow: boolean
  role: string
  token: string
  tokenType: string
  twoFactorEnabled: boolean
  userId: string
  username: string
}

verifyCookie().then((user: IUser|undefined) => {
  new Vue({
    store,
    render: (h: (createElement: VueConstructor<Vue>, hack: DefaultProps) => VNode ) => {
      return h(App, { props: { user } })
    }
  }).$mount("#app");
});
