/* eslint-disable @typescript-eslint/ban-types */
import { Machine, StateSchema } from "xstate";
import { IncomingResponse } from "sip.js/lib/core";

export interface PhoneStateSchema extends StateSchema {
  states: {
    wsNotConnected: {};
    wsConnecting: {};
    wsConnectionFailed: {};
    wsConnected: {
      states: {
        resting: {};
        ringing: {};
        incomingCall: {};
        incomingCallMissed: {};
        incomingCallRejected: {};
        cancelled: {};
        callFailed: {};
        callInProgress: {
          states: {
            held: {};
            inProgress: {
              states: {
                notMuted: {};
                muted: {};
              };
            };
          };
        };
      };
    };
  };
}

export type PhoneEvent =
  | { type: "CONNECT" }
  | { type: "CONNECTED" }
  | { type: "DISCONNECTED" }
  | { type: "CALL" }
  | { type: "INCOMING_CALL" }
  | { type: "ANSWERED" }
  | { type: "CANCEL" }
  | { type: "ANSWER" }
  | { type: "REJECT" }
  | { type: "PROCEED" }
  | { type: "HANGUP" }
  | { type: "UNHOLD" }
  | { type: "HOLD" }
  | { type: "MUTE" }
  | { type: "UNMUTE" }
  | { type: "ERROR" }
  | { type: "REJECTED"; resp?: IncomingResponse };

export interface PhoneStateContext {
  id: string;
}

export const stateMachine = Machine<
  PhoneStateContext,
  PhoneStateSchema,
  PhoneEvent
>({
  id: "softPhoneSM",
  context: {
    /* some data */
    id: "123"
  },
  initial: "wsNotConnected",
  states: {
    wsNotConnected: {
      on: { CONNECT: "wsConnecting" }
    },
    wsConnecting: {
      on: {
        CONNECTED: "wsConnected",
        ERROR: "wsConnectionFailed"
      }
    },
    wsConnectionFailed: {
      on: {
        CONNECT: "wsConnecting"
      }
    },
    wsConnected: {
      initial: "resting",
      on: {
        CALL: "wsConnected.ringing",
        INCOMING_CALL: "wsConnected.incomingCall",
        DISCONNECTED: "wsNotConnected"
      },
      states: {
        resting: {
          id: "root_resting",
          on: { CALL: "ringing" }
        },
        ringing: {
          on: {
            ANSWERED: "callInProgress",
            CANCEL: "cancelled",
            REJECTED: "callFailed"
          }
        },
        callFailed: {
          on: {
            PROCEED: "resting"
          }
        },
        incomingCall: {
          on: {
            ANSWER: "callInProgress",
            REJECT: "incomingCallRejected",
            HANGUP: "incomingCallMissed"
          }
        },
        incomingCallMissed: {
          on: {
            PROCEED: "resting"
          }
        },
        incomingCallRejected: {
          on: {
            PROCEED: "resting"
          }
        },
        cancelled: {
          id: "root_cancelled",
          on: {
            PROCEED: "resting"
          }
        },
        callInProgress: {
          initial: "inProgress",
          on: {
            HANGUP: "resting"
          },
          states: {
            held: {
              on: {
                UNHOLD: "inProgress",
                CANCEL: "#root_cancelled"
              }
            },
            inProgress: {
              initial: "notMuted",
              on: {
                HANGUP: "#root_resting",
                HOLD: "held"
              },
              states: {
                notMuted: {
                  on: {
                    MUTE: "muted"
                  }
                },
                muted: {
                  on: {
                    UNMUTE: "notMuted"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
