






import Vue from "vue";
import Dialer from "@/views/Dialer.vue";
import { Component, Prop } from "vue-property-decorator";
import store from "@/store";
import { UserModel } from "./models";

@Component({
  components: {
    Dialer,
  },
})
export default class App extends Vue {
  @Prop() readonly user!: UserModel;

  created(): void {
    store.dispatch("fetchLocalStorageData");
    store.dispatch("setCurrentUser", this.user);
  }
}
