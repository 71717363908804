import config from "@/config";
import store from "@/store";
import { Flows } from "@onereach/sdk";

export interface Flow {
  id: string
  botId: string
  data: {
    label: string
  }
}

export const getFlowsApi = (): {
  getFlows: (request: {query: { id: string[] }}) => Promise<Flow[]>
  userTokenFactory: () => string
  accountId: string
  dataHubUrl: string,
  deployerUrl: string,
  sdkApiUrl: string,
} => new Flows({
  userTokenFactory: () => `USER ${store.state.user.token}`,
  accountId: store.state.user.accountId,
  dataHubUrl: config.DATA_HUB_URL,
  deployerUrl: config.DEPLOYER_URL,
  sdkApiUrl: config.SDK_API_URL,
});

export default getFlowsApi;
